import React, { useEffect, useState } from "react";
import enhancer from "./enhancer/productenhancer";
import NavigationActions from "redux/navigation/actions";
import { useParams, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { Button } from "reactstrap";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CreatableSelect from "react-select/creatable";

import {
  addProduct,
  getProductById,
  updateProduct
} from "services/productServices";
import {
  getCategories,
  getCategoryByParentId,
  getParentCategories
} from "services/categoryServices";
import { getBrands } from "services/brandServices";
import { getColors } from "services/colorServices";
import { getPapers } from "services/paperServices";
import { getSizes } from "services/sizeServices";
import { getMarkers } from "services/markerServices";
import "../../../assets/css/thumbnail.css";
import ReactSelect from "react-select";
// import { getValues } from "languageProvider/conversion";

const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

const ProductsAddModal = props => {
  //VARIABLES
  const {
    token,
    success,
    fetching,
    isFetching,
    error,
    setFieldValue,
    values,
    handleChange,
    resetForm,
    handleSubmit,
    setValues,
    isValid,
    handleBlur,
    handleReset,
    errors,
    // setFieldTouched,
    touched,
    submitCount
  } = props;

  const { id } = useParams();

  //USESTATE

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [parentCatOptions, setParentCatOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [colorOptions, setcolorOptions] = useState([]);
  const [paperOptions, setPaperOptions] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  //eslint-disable-next-line
  const [markerOptions, setMarkerOptions] = useState([]);
  const [img, setImg] = useState({ product_img: "" });
  const [brandImg, setBrandImg] = useState([]);
  const [brandimagArr, setBrandImgArr] = useState([]);
  const [productImage, setProductImage] = useState();
  const [categoryName, setCategoryName] = useState("");
  const [brandName, setBrandName] = useState([]);

  const statusArr = [
    { label: "Active", value: "Active" },
    { label: "Draft", value: "Draft" }
  ];

  // FUNCTIONS

  //List API CALLING

  const List_allItems = async () => {
    await getCategories(token).then(data => {
      if (data.success) {
        success();
        setCategoryOptions(
          data.data.map(val => ({ value: val.id, label: val.name }))
        );
      } else {
        error(data.message);
      }
    });

    await getParentCategories(token).then(data => {
      if (data.success) {
        success();
        setParentCatOptions(
          data.data.map(val => ({ value: val.id, label: val.name }))
        );
      } else {
        error(data.message);
      }
    });

    await getBrands(token).then(data => {
      if (data.success) {
        success();
        setBrandOptions(
          data.data.map(val => ({ value: val.id, label: val.name }))
        );
      } else {
        error(data.message);
      }
    });

    await getColors(token).then(data => {
      if (data.success) {
        success();
        setcolorOptions(
          data.data.map(val => ({ value: val.id, label: val.color }))
        );
      } else {
        error(data.message);
      }
    });

    await getPapers(token).then(data => {
      if (data.success) {
        success();
        setPaperOptions(
          data.data.map(val => ({ value: val.id, label: val.paper }))
        );
      } else {
        error(data.message);
      }
    });

    await getSizes(token).then(data => {
      if (data.success) {
        success();
        setSizeOptions(
          data.data.map(val => ({ value: val.id, label: val.size }))
        );
      } else {
        error(data.message);
      }
    });

    await getMarkers(token).then(data => {
      if (data.success) {
        success();
        setMarkerOptions(
          data.data.map(val => ({ value: val.id, label: val.marker }))
        );
      } else {
        error(data.message);
      }
    });
  };

  const get_Product_list = async () => {
    const id_data = { id: id };

    await getProductById(token, id_data).then(data => {
      if (data.success) {
        success();
        // const papers = []
        // const colors = []
        // const paperdata = data.data.papers ? data.data?.papers.map(val=> papers.push([{...val,"Product_SKU":val.product_SKU}])): papers;
        // const colordata = data.data.colors ? data.data?.colors.map(val=> colors.push([{...val,"color_SKU":val.product_SKU}])): colors;
        setValues({
          ...data.data,
          colors:
            data.data.colors?.length > 0
              ? data.data?.colors
              : [{ position: 1, color_id: "", Product_SKU: "" }],
          papers:
            data.data.papers?.length > 0
              ? data.data?.papers
              : [{ position: 1, paper_type_id: "", Product_SKU: "" }],
          deleted_brands: [],
          deleted_colors: [],
          deleted_papers: []
        });
      } else {
        error(data.message);
      }
    });
  };

  const getCategory_By_ParentId = async val => {
    if (val) {
      await getCategoryByParentId(token, { parent_id: val }).then(data => {
        if (data.success) {
          success();

          setCategoryOptions(
            data.data.map(val => ({ value: val.id, label: val.name }))
          );

          data.data?.find(x => x.id === values.category_id) &&
            setCategoryName(
              data.data?.find(x => x.id === values.category_id)?.name
            );
        } else {
          error(data.message);
        }
      });
    }
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const BrandsError = props => {
    const field1 = props.field;
    const index = props.index;
    if (
      (errors &&
        errors.hasOwnProperty("brands") &&
        errors?.brands[index] &&
        errors?.brands[index][field1] &&
        touched &&
        touched.hasOwnProperty("brands") &&
        touched?.brands[index] &&
        touched?.brands[index][field1]) ||
      submitCount > 0
    ) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors &&
            errors.hasOwnProperty("brands") &&
            errors?.brands[index] &&
            errors?.brands[index][field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  //COLOR
  const ColorsError = props => {
    const field1 = props.field;
    const index = props.index;
    if (
      (errors &&
        errors.hasOwnProperty("colors") &&
        errors?.colors[index] &&
        errors?.colors[index][field1] &&
        touched &&
        touched.hasOwnProperty("colors") &&
        touched?.colors[index] &&
        touched?.colors[index][field1]) ||
      submitCount > 0
    ) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors &&
            errors.hasOwnProperty("colors") &&
            errors?.colors[index] &&
            errors?.colors[index][field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  //PAPERS
  const PapersError = props => {
    const field1 = props.field;
    const index = props.index;
    if (
      (errors &&
        errors.hasOwnProperty("papers") &&
        errors?.papers[index] &&
        errors?.papers[index][field1] &&
        touched &&
        touched.hasOwnProperty("papers") &&
        touched?.papers[index] &&
        touched?.papers[index][field1]) ||
      submitCount > 0
    ) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors &&
            errors.hasOwnProperty("papers") &&
            errors?.papers[index] &&
            errors?.papers[index][field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  //IMAGE CHANGE
  const onProductImageChange = e => {
    const [file] = e.target.files;
    if (file) {
      setImg({ ...img, product_img: URL.createObjectURL(file) });
    }
  };

  const onBrandImageChange = (e, k) => {
    const [file] = e.target.files;
    if (file) {
      brandImg[k] = URL.createObjectURL(file);
      setBrandImg(brandImg);
    } else {
      brandImg[k] = "";
      setBrandImg(brandImg);
    }
  };

  const handleProductSubmit = async e => {
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      var formData = new FormData();
      for (const val in values) {
        if (val === "deleted_brands") {
          formData.append(val, JSON.stringify(values[val]));
        } else if (val === "deleted_papers") {
          formData.append(val, JSON.stringify(values[val]));
        } else if (val === "deleted_colors") {
          formData.append(val, JSON.stringify(values[val]));
        } else if (val === "brands") {
          brandimagArr.map(x => formData.append("brand_image", x));
          formData.append(val, JSON.stringify(values[val]));
        } else if (val === "papers") {
          formData.append(val, JSON.stringify(values[val]));
        } else if (val === "colors") {
          formData.append(val, JSON.stringify(values[val]));
        } else if (val === "product_image") {
          formData.append(val, productImage ? productImage : values[val]);
        } else if (
          val === "color_id" ||
          val === "size_id" ||
          val === "marker_id" ||
          val === "paper_type_id"
        ) {
          formData.append(
            val,
            val && val !== null && val !== undefined ? values[val] : 0
          );
        } else {
          formData.append(val, values[val]);
        }
      }

      fetching();
      id
        ? updateProduct(token, id, formData).then(data => {
            if (data.success) {
              success(data.message);
              // props.history.push("/products");
            } else {
              error(data.message);
            }
          })
        : addProduct(token, formData).then(data => {
            if (data.success) {
              success(data.message);
              window.location.reload();
              // setProductImage()
              // resetForm();
              // props.history.push("/products/Add");
            } else {
              error(data.message);
            }
          });
    }
  };

  //USEEFFECTS

  useEffect(() => {
    id && get_Product_list();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (values.parent_category_id) {
      getCategory_By_ParentId(values.parent_category_id);
    }
    // eslint-disable-next-line
  }, [values.parent_category_id]);

  useEffect(() => {
    List_allItems();
    // eslint-disable-next-line
  }, []);

  // console.log("values", values);

  useEffect(() => {
    if (values.brands?.length > 0 && brandOptions?.length > 0) {
      var brandArr = values.brands.map(
        x => brandOptions.find(f => f.value === x.brand_id)?.label
      );
      setBrandName(brandArr);
    }
    // eslint-disable-next-line
  }, [brandOptions, values]);

  return (
    <div className="" style={{ margin: "0 8px" }}>
      <div className="mt-10">
        {/* <div className="row">
            <div className="col-12 mb-2">
              <span style={{ fontSize: "20px", fontWeight: "bolder" }}>{`${
                id ? "Edit" : "Add"
              } Product`}</span>
            </div>
          </div> */}

        {/* ADD PRODUCT */}

        {/* <div className="card-body"> */}
        <div className="row mt-10 mb-10">
          <div className="col-6 mb-2">
            <span style={{ fontSize: "18px", fontWeight: "bold" }}>
              Product Details
            </span>
          </div>
          <div className="col-6 text-right mb-2" style={{ marginTop: "-16px" }}>
            <span className="mt-3">
              <Button
                className="btn c-primary px-5 add_product_design"
                onClick={e => handleProductSubmit(e)}
                type="button"
                disabled={isFetching}
              >
                {id ? "Update" : "Save"}
              </Button>
            </span>
          </div>
        </div>
      </div>
      <div className="card p-3 mb-16">
        <div className="row ">
          {/* sec_border */}

          <div className="col-12">
            {/* col-md-4 */}
            <div className="form-group">
              <label>
                Title <span className="error-msg">*</span>
              </label>
              <input
                type="text"
                className="form-control form"
                placeholder="Enter The Product Name"
                id="product_name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.product_name}
              />
              <Error field="product_name" />
            </div>
          </div>
          <div className="col-12">
            {/* col-md-4 */}
            <div className="form-group">
              <label>
                Parent Category <span className="error-msg">*</span>
              </label>
              <ReactSelect
                isClearable
                value={
                  parentCatOptions.find(
                    x => x.value === values.parent_category_id
                  )
                    ? parentCatOptions.find(
                        x => x.value === values.parent_category_id
                      )
                    : null
                }
                onChange={val => {
                  setFieldValue("parent_category_id", val?.value);
                  setFieldValue("category_id", "");
                }}
                options={parentCatOptions}
              />
              <Error field="parent_category_id" />
            </div>
          </div>

          <div className="col-12">
            {/* col-md-4 */}
            <div className="form-group">
              <label>
                Category <span className="error-msg">*</span>
              </label>
              <CreatableSelect
                isClearable
                value={
                  values.category_name &&
                  values.category_name !== null &&
                  values.category_id === 0
                    ? {
                        value: values.category_name,
                        label: values.category_name
                      }
                    : categoryOptions.find(x => x.value === values.category_id)
                    ? categoryOptions.find(x => x.value === values.category_id)
                    : null
                }
                onCreateOption={val => {
                  setFieldValue("category_id", 0);
                  setFieldValue("category_name", val);
                  setCategoryName(val);
                }}
                onChange={val => {
                  if (val) {
                    setFieldValue("category_id", val?.value);
                    setCategoryName(val.label);
                  } else if (val === null) {
                    setFieldValue("category_name");
                    setFieldValue("category_id", "");
                  }
                }}
                options={values.parent_category_id ? categoryOptions : null}
              />
              <Error field="category_id" />
              <Error field="category_name" />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label>
                Product Status <span className="error-msg">*</span>
              </label>
              <ReactSelect
                isClearable
                value={
                  statusArr.find(x => x.value === values.product_status)
                    ? statusArr.find(x => x.value === values.product_status)
                    : null
                }
                onChange={val => {
                  setFieldValue("product_status", val?.value);
                  // setFieldValue("category_id", "");
                }}
                options={statusArr}
              />
              <Error field="product_status" />
            </div>
          </div>
          <div className="col-12">
            {/* col-md-6 mb-16 */}
            <label className="d-block my-2">
              Product Quantity <span className="error-msg">*</span>
            </label>
            <textarea
              className="form-control"
              id="product_quantity"
              onBlur={handleBlur}
              placeholder="Please Enter The Product Quantity"
              onChange={handleChange}
              value={values.product_quantity}
            ></textarea>
            <div>
              <Error field="product_quantity" />
            </div>
          </div>

          <div className="col-12 mb-16">
            {/* col-md-6 mb-16 */}
            <label className="d-block my-2">SKU</label>
            <input
              type="text"
              className="form-control"
              id="SKU"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.SKU}
            />
          </div>
        </div>
      </div>

      {/* ATTRIBUTES */}

      <div className="row">
        <div className="col-12 my-2">
          <span style={{ fontSize: "18px", fontWeight: "bold" }}>
            Product Attributes
          </span>
        </div>
      </div>

      <div className="card p-3 mb-16">
        {/*  <div className="row sec_border">
            {values?.category_id !== 21 ? (
              <div className="col-md-3">
                <div className="form-group">
                  <label>
                    Color
                    // {/* <span className="error-msg">*</span> */}
        {/* </label>
                  <CreatableSelect
                    isClearable
                    value={
                      //&&  values.color_name !== null
                      values.color_name && values.color_id === 0
                        ? { value: values.color_name, label: values.color_name }
                        : colorOptions.find(x => x.value === values.color_id)
                        ? colorOptions.find(x => x.value === values.color_id)
                        : null
                    }
                    onCreateOption={val => {
                      // setFieldValue("color_ext", true);
                      setFieldValue("color_name", val);
                      setFieldValue("color_id", 0);
                    }}
                    onBlur={val => {
                      setFieldTouched("color_name", val);
                    }}
                    onChange={val => {
                      if (val) {
                        setFieldValue("color_id", val?.value);
                      } else if (val === null) {
                        setFieldValue("color_name");
                        setFieldValue("color_id", 0);
                      } else {
                        setFieldValue("color_id", 0);
                      }
                    }}
                    options={colorOptions}
                  />
                  <Error field="color_id" />
                  <Error field="color_name" />
                </div>
              </div>
            ) : (
              <></>
            )} */}
        <div className="col-md-6">
          <div className="form-group">
            <label>
              Size
              {/* <span className="error-msg">*</span> */}
            </label>
            <CreatableSelect
              isClearable
              value={
                //&&  values.size_val !== null
                values.size_val && values.size_id === 0
                  ? { value: values.size_val, label: values.size_val }
                  : sizeOptions.find(x => x.value === values.size_id)
                  ? sizeOptions.find(x => x.value === values.size_id)
                  : null
              }
              onCreateOption={val => {
                setFieldValue("size_val", val);
                setFieldValue("size_id", 0);
              }}
              onChange={val => {
                if (val) {
                  setFieldValue("size_id", val?.value);
                } else if (val === null) {
                  setFieldValue("size_val");
                  setFieldValue("size_id", 0);
                } else {
                  setFieldValue("size_id", 0);
                }
              }}
              options={sizeOptions}
            />
            <Error field="size_id" />
          </div>
        </div>
        {/*{values?.category_id !== 22 ? (
              <div className="col-md-3">
                <div className="form-group">
                  <label>
                    Paper Type
                    {/* <span className="error-msg">*</span> */}
        {/* </label>
                  <CreatableSelect
                    isClearable
                    value={
                      // &&  values.paper_type !== null
                      values.paper_type && values.paper_type_id === 0
                        ? { value: values.paper_type, label: values.paper_type }
                        : paperOptions.find(
                            x => x.value === values.paper_type_id
                          )
                        ? paperOptions.find(
                            x => x.value === values.paper_type_id
                          )
                        : null
                    }
                    onCreateOption={val => {
                      setFieldValue("paper_type", val);
                      setFieldValue("paper_type_id", 0);
                    }}
                    onChange={val => {
                      if (val) {
                        setFieldValue("paper_type_id", val?.value);
                      } else if (val === null) {
                        setFieldValue("paper_type");
                        setFieldValue("paper_type_id", 0);
                      } else {
                        setFieldValue("paper_type_id", 0);
                      }
                    }}
                    options={paperOptions}
                  />
                  <Error field="paper_type_id" />
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="col-md-3">
              <div className="form-group">
                <label>
                  Marker
                  {/* <span className="error-msg">*</span> */}
        {/* </label>
                <CreatableSelect
                  isClearable
                  value={
                    //&& values.marker_val !== null
                    values.marker_val && values.marker_id === 0
                      ? { value: values.marker_val, label: values.marker_val }
                      : markerOptions.find(x => x.value === values.marker_id)
                      ? markerOptions.find(x => x.value === values.marker_id)
                      : null
                  }
                  onCreateOption={val => {
                    setFieldValue("marker_val", val);
                    setFieldValue("marker_id", 0);
                  }}
                  onChange={val => {
                    if (val) {
                      setFieldValue("marker_id", val?.value);
                    } else if (val === null) {
                      setFieldValue("marker_val");
                      setFieldValue("marker_id", 0);
                    } else {
                      setFieldValue("marker_id", 0);
                    }
                  }}
                  options={markerOptions}
                />
                <Error field="marker_id" />
              </div>
            </div>
          </div>*/}
      </div>

      {/*FEATURED IMAGE */}

      <div className="row">
        <div className="col-12 my-2 sec_height mb-16">
          <span style={{ fontSize: "18px", fontWeight: "bold" }}>
            Product Featured Image{" "}
          </span>
        </div>
      </div>
      <div
        className="card p-3 mb-16"
        // className={
        //   values.category_id === 22 || values.category_id === 21
        //     ? "row sec_border"
        //     : "row"
        // }
      >
        <div className="row">
          <div className="col-md-5">
            <div>
              <label>
                Product Image<span className="error-msg"> *</span>
              </label>
            </div>
            <input
              type="file"
              className="mr-2"
              // value={values?.product_image}
              // id="product_image"
              accept="image/png, image/gif, image/jpeg"
              // onBlur={handleBlur}
              onChange={e => {
                if (e.target.files[0]) {
                  onProductImageChange(e);
                  setProductImage(e.target.files[0]);
                  setFieldValue("product_image", e.target.files[0]?.name);
                } else {
                  setProductImage();
                  setFieldValue("product_image", "");
                }
              }}
            />
            {productImage ? (
              <>
                <a
                  href={img.product_img}
                  alt={"product_image"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={
                    img.product_img ? "d-block thumbnail_img" : "d-none"
                  }
                >
                  <img src={img.product_img} alt="product-img" />
                </a>
              </>
            ) : (
              <a
                href={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${values?.product_image}`}
                alt={"product_image2"}
                target="_blank"
                rel="noopener noreferrer"
                className={
                  values?.product_image ? "d-block thumbnail_img" : "d-none"
                }
              >
                <img
                  src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${values?.product_image}`}
                  alt="product-img"
                />
              </a>
            )}

            <div>
              {/* <div>

              <a href = {values?.product_image}
                alt={"product_image"}
                target="_blank"
                rel="noopener noreferrer">
                <img src ={values?.product_image} alt={values?.product_image}/>

              </a>
                    </div> */}
              <Error field="product_image" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mt-4">
              <input
                type="checkbox"
                className="mr-2 react-form-input"
                id="show_on_home_page"
                checked={values.show_on_home_page ? true : false}
                onBlur={handleBlur}
                onChange={e => {
                  e.target.checked === true
                    ? setFieldValue("show_on_home_page", 1)
                    : setFieldValue(`show_on_home_page`, 0);
                }}
              />
              <label>
                <span> Show on Homepage </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* IF CATEGORY ==  FLYERS */}

      {values.category_id === 22 ? (
        <>
          <div className="row">
            <div className="col-9 ">
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                Paper Choice{" "}
              </span>

              {/* <span className="error-msg">*</span> */}
            </div>
            <div className="col-3 text-right">
              <button
                className="btn btn-white border-0"
                onClick={() => {
                  values.papers.push({
                    position: values.papers.length + 1,
                    paper_type_id: "",
                    Product_SKU: ""
                  });
                  setValues(values);
                }}
              >
                {/* <i
                    class="fas fa-plus-circle"
                    style={{ color: "#000000" }}
                  ></i> */}
                <AddCircleOutlineIcon className="plus_add_btn" />
              </button>
            </div>
          </div>

          <div className="card mb-16 p-3">
            <div className="row">
              <div className="col-5">
                <div>
                  <label>
                    Select Paper Type
                    <span className="error-msg ml-1">*</span>
                  </label>
                </div>
              </div>
              <div className="col-5">
                <div>
                  <label>
                    Paper Type SKU
                    <span className="error-msg ml-1">*</span>
                  </label>
                </div>
              </div>
            </div>

            {values.papers !== undefined &&
              values.papers.map((s, k) => (
                <div className="row" key={k}>
                  <div className="col-md-5 mb-16">
                    {/* <div>
                      <label>
                        Select Paper Type
                        <span className="error-msg ml-1">*</span>
                      </label>
                    </div> */}
                    <ReactSelect
                      isClearable
                      value={
                        paperOptions.find(
                          x => x.value === values.papers[k].paper_type_id
                        )
                          ? paperOptions.find(
                              x => x.value === values.papers[k].paper_type_id
                            )
                          : null
                      }
                      placeholder="Select Paper Type"
                      onChange={val => {
                        setFieldValue(`papers[${k}].paper_type_id`, val?.value);
                      }}
                      options={paperOptions.filter(
                        o1 =>
                          !values.papers.some(
                            o2 => o1.value === o2.paper_type_id
                          )
                      )}
                    />

                    <PapersError field="paper_type_id" index={k} />
                  </div>

                  <div className="col-md-5">
                    <div className="form-group">
                      <input
                        value={values.papers[k].Product_SKU}
                        type="test"
                        className="mr-2 form-control form"
                        id={`papers[${k}].Product_SKU`}
                        onBlur={handleBlur}
                        onChange={e => {
                          setFieldValue(
                            `papers[${k}].Product_SKU`,
                            e.target.value
                          );
                        }}
                      />
                    </div>
                    <PapersError field="Product_SKU" index={k} />
                  </div>
                  <div className="col-md-2">
                    <div className="text-right color-black">
                      <button
                        className="btn btn-link btn-white border-0 react-form-input"
                        type="button"
                        disabled={values.papers.length <= 1}
                        onClick={() => {
                          if (id) {
                            values.deleted_papers.push(values.papers[k].id);
                          }
                          values.papers.splice(k, 1);
                          setValues(values);
                        }}
                      >
                        {/* <i
                          class="fas fa-minus-circle"
                          style={{ color: "#000000" }}
                        ></i> */}
                        <RemoveCircleOutlineIcon />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <></>
      )}

      {/* IF CATEGORY == ENVELOP  */}
      {values.category_id === 21 ? (
        <>
          <div className="row">
            <div className="col-9 ">
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                Color Choice{" "}
              </span>

              {/* <span className="error-msg">*</span> */}
            </div>
            <div className="col-3 text-right">
              <button
                className="btn btn-white border-0"
                onClick={() => {
                  values.colors.push({
                    position: values.colors.length + 1,
                    color_id: "",
                    Product_SKU: ""
                  });
                  setValues(values);
                }}
              >
                {/* <i
                    class="fas fa-plus-circle"
                    style={{ color: "#000000" }}
                  ></i> */}
                <AddCircleOutlineIcon className="plus_add_btn" />
              </button>
            </div>
          </div>

          <div className="card mb-16 p-3">
            <div className="row">
              <div className="col-5">
                <div>
                  <label>
                    Select Color
                    <span className="error-msg ml-1">*</span>
                  </label>
                </div>
              </div>
              <div className="col-5">
                <div>
                  <label>
                    Color SKU
                    <span className="error-msg ml-1">*</span>
                  </label>
                </div>
              </div>
            </div>

            {values.colors !== undefined &&
              values.colors.map((s, k) => (
                <div className="row" key={k}>
                  <div className="col-md-5 mb-16">
                    <ReactSelect
                      isClearable
                      value={
                        colorOptions.find(
                          x => x.value === values.colors[k].color_id
                        )
                          ? colorOptions.find(
                              x => x.value === values.colors[k].color_id
                            )
                          : null
                      }
                      placeholder="Select Color "
                      onChange={val => {
                        setFieldValue(`colors[${k}].color_id`, val?.value);
                      }}
                      options={colorOptions.filter(
                        o1 =>
                          !values.colors.some(o2 => o1.value === o2.color_id)
                      )}
                    />

                    <ColorsError field="color_id" index={k} />
                  </div>

                  <div className="col-md-5">
                    <div className="form-group">
                      <input
                        value={values.colors[k].Product_SKU}
                        type="test"
                        className="mr-2 form-control form"
                        id={`colors[${k}].Product_SKU`}
                        onBlur={handleBlur}
                        onChange={e => {
                          setFieldValue(
                            `colors[${k}].Product_SKU`,
                            e.target.value
                          );
                        }}
                      />
                    </div>
                    <ColorsError field="Product_SKU" index={k} />
                  </div>
                  <div className="col-md-2">
                    <div className="text-right color-black">
                      <button
                        className="btn btn-link btn-white border-0 react-form-input"
                        type="button"
                        disabled={values.colors.length <= 1}
                        onClick={() => {
                          if (id) {
                            values.deleted_colors.push(values.colors[k].id);
                          }
                          values.colors.splice(k, 1);
                          setValues(values);
                        }}
                      >
                        {/* <i
                          class="fas fa-minus-circle"
                          style={{ color: "#000000" }}
                        ></i> */}
                        <RemoveCircleOutlineIcon />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <></>
      )}

      {/* BRANDS */}

      {/* sec_border */}

      <div className="row ">
        <div className="col-9 ">
          <span style={{ fontSize: "18px", fontWeight: "bold" }}>
            Product Brands{" "}
          </span>

          {/* <span className="error-msg">*</span> */}
        </div>
        <div className="col-3 text-right">
          <button
            className="btn btn-white border-0"
            onClick={() => {
              values.brands.push({
                position: values.brands.length + 1,
                brand_id: "",
                brandimg: "",
                show_on_homepage: 0
              });
              setValues(values);
            }}
          >
            {/* <i class="fas fa-plus-circle" style={{ color: "#000000" }}></i> */}
            <AddCircleOutlineIcon className="plus_add_btn" />
          </button>
        </div>
      </div>
      <div className="card p-3 mb-16">
        {values.brands !== undefined &&
          values.brands.map((s, k) => (
            <div className="row" key={k}>
              <div className="col-md-5 mb-16">
                <div>
                  <label>
                    Select Brand
                    {/* <span className="error-msg ml-1">*</span> */}
                  </label>
                </div>
                <ReactSelect
                  isClearable
                  value={
                    brandOptions.find(
                      x => x.value === values.brands[k].brand_id
                    )
                      ? brandOptions.find(
                          x => x.value === values.brands[k].brand_id
                        )
                      : null
                  }
                  placeholder="Select Brand"
                  onChange={val => {
                    brandName[k] = val?.label;
                    setBrandName(brandName);
                    setFieldValue(`brands[${k}].brand_id`, val?.value);
                  }}
                  options={brandOptions.filter(
                    o1 => !values.brands.some(o2 => o1.value === o2.brand_id)
                  )}
                />

                <BrandsError field="brand_id" index={k} />
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <div>
                    <label>
                      Select Brand Image
                      {/* <span className="error-msg"> *</span> */}
                    </label>
                  </div>
                  <input
                    type="file"
                    className="mr-2"
                    id={`brands[${k}].brandimg`}
                    accept="image/png, image/gif, image/jpeg"
                    onBlur={handleBlur}
                    onChange={e => {
                      if (e.target.files[0]) {
                        onBrandImageChange(e, k);
                        setBrandImgArr([...brandimagArr, e.target.files[0]]);
                        setFieldValue(
                          `brands[${k}].brandimg`,
                          e.target.files[0]?.name
                        );
                      } else {
                        setFieldValue(`brands[${k}].brandimg`, "");
                        brandimagArr[k] = "";
                        setBrandImgArr([...brandimagArr]);
                      }
                    }}
                  />

                  <>
                    {values.brands[k].brandimg !== "" && brandImg[k] ? (
                      <a
                        href={brandImg[k]}
                        alt={"product_image"}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="thumbnail_img"
                      >
                        <img src={brandImg[k]} alt="product-img" />
                      </a>
                    ) : (
                      <>
                        <a
                          href={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${values.brands[k].brandimg}`}
                          alt={"brand_image"}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={
                            values.brands[k].brandimg
                              ? "d-block thumbnail_img"
                              : "d-none"
                          }
                        >
                          <img
                            src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${values.brands[k].brandimg}`}
                            alt="product-img"
                          />
                        </a>
                      </>
                    )}
                  </>
                </div>
                <BrandsError field="brandimg" index={k} />
              </div>

              {/* <div className="col-md-3 mt-4 pt-2">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        className="mr-2 react-form-input"
                        checked={
                          values.brands[k].show_on_homepage ? true : false
                        }
                        id={`brands[${k}].show_on_homepage`}
                        onBlur={handleBlur}
                        onChange={e => {
                          e.target.checked === true
                            ? setFieldValue(`brands[${k}].show_on_homepage`, 1)
                            : setFieldValue(`brands[${k}].show_on_homepage`, 0);
                        }}
                      />
                      <label htmlFor={`brands[${k}].show_on_homepage`}>
                        <span> Show on Homepage </span>
                      </label>
                    </div>
                  </div> */}
              <div className="col-md-3">
                <div className="text-right color-black">
                  <button
                    className="btn btn-link mt-4 pt-2 btn-white border-0 react-form-input"
                    type="button"
                    // disabled={values.brands.length <= 1}
                    onClick={() => {
                      if (id) {
                        values.deleted_brands.push(values.brands[k].id);
                      }
                      values.brands.splice(k, 1);
                      setValues(values);
                    }}
                  >
                    {/* <i
                        class="fas fa-minus-circle"
                        style={{ color: "#000000" }}
                      ></i> */}
                    <RemoveCircleOutlineIcon />
                  </button>
                </div>
              </div>

              <div className="col-12 mb-10">
                {values?.brands[k]?.brand_id && values.category_id
                  ? "BrandURL : "
                  : ""}

                <a
                  href={
                    values?.brands[k]?.brand_id &&
                    values?.parent_category_id === 1 &&
                    values.category_id
                      ? `${
                          process.env.REACT_APP_HOMEPAGE_URI
                        }/categories/${categoryName
                          .replaceAll(" ", "-")
                          .toLowerCase()}/${brandName[k]}`
                      : values?.brands[k]?.brand_id &&
                        values?.parent_category_id === 2 &&
                        values.category_id
                      ? `${
                          process.env.REACT_APP_HOMEPAGE_URI
                        }/printing-products/${categoryName
                          .replaceAll(" ", "-")
                          .toLowerCase()}/${brandName[k]}`
                      : ""
                  }
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {values?.brands[k]?.brand_id &&
                  values?.parent_category_id === 1 &&
                  values.category_id
                    ? ` ${
                        process.env.REACT_APP_HOMEPAGE_URI
                      }/categories/${categoryName
                        .replaceAll(" ", "-")
                        .toLowerCase()}/${brandName[k]}`
                    : values?.brands[k]?.brand_id &&
                      values?.parent_category_id === 2 &&
                      values.category_id
                    ? `${
                        process.env.REACT_APP_HOMEPAGE_URI
                      }/printing-products/${categoryName
                        .replaceAll(" ", "-")
                        .toLowerCase()}/${brandName[k]}`
                    : ""}
                </a>
              </div>
            </div>
          ))}
      </div>

      {/* BUTTON */}
      <div className="row">
        <div className="col-12 text-center mt-3">
          <Button
            className="btn c-primary px-5 add_product_design"
            onClick={e => handleProductSubmit(e)}
            type="button"
            disabled={isFetching}
          >
            {id ? "Update" : "Save"}
          </Button>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(ProductsAddModal);
